exports.components = {
  "component---src-components-templates-list-authors-tsx": () => import("./../../../src/components/templates/list/Authors.tsx" /* webpackChunkName: "component---src-components-templates-list-authors-tsx" */),
  "component---src-components-templates-list-default-tsx": () => import("./../../../src/components/templates/list/Default.tsx" /* webpackChunkName: "component---src-components-templates-list-default-tsx" */),
  "component---src-components-templates-list-tags-tsx": () => import("./../../../src/components/templates/list/Tags.tsx" /* webpackChunkName: "component---src-components-templates-list-tags-tsx" */),
  "component---src-components-templates-post-tsx": () => import("./../../../src/components/templates/post.tsx" /* webpackChunkName: "component---src-components-templates-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cto-message-tsx": () => import("./../../../src/pages/cto_message.tsx" /* webpackChunkName: "component---src-pages-cto-message-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mvv-tsx": () => import("./../../../src/pages/mvv.tsx" /* webpackChunkName: "component---src-pages-mvv-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-tech-tsx": () => import("./../../../src/pages/tech.tsx" /* webpackChunkName: "component---src-pages-tech-tsx" */)
}

